<template>
  <v-container fluid class="pa-0">
    <hero-title title="request.requestDetails" :subtitle="serviceRequest.serviceRequest ? `Igény azonosító: ${serviceRequest.serviceRequest.id}` : ''"></hero-title>
    <template v-if="loginIsSuccess">
      <doc-tmx-garancia :service-request="serviceRequest"></doc-tmx-garancia>
    </template>
    <template v-else>
      <v-container class="pt-10">
        <v-row justify="center" align="center">
          <v-col cols="12" v-if="loadingRequestData" class="text-center">
            <v-progress-circular color="primary" size="100" indeterminate></v-progress-circular>
          </v-col>
          <v-col cols="12" class="text-center">
            <template v-if="loadingRequestData">
              <div class="text-h4">
                {{ $t('documentViewer.loadingData') }}
              </div>
            </template>
            <template v-else>
              <div class="text-h4 mb-10">
                {{ $t('documentViewer.loadingFailed')}}
              </div>
              <v-btn color="primary" large @click="reloadPage">
                {{ $t('button.refresh') }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-container>
</template>

<script>
import HeroTitle from "../../components/titles/HeroTitle.vue";
import DocTmxFizetos from 'src/components/doctmx/DocTmxFizetos';
import DocTmxGarancia from 'src/components/doctmx/DocTmxGarancia';
import dayjs from 'dayjs';
import { getToken } from 'src/utils/jwtHelper';

export default {
  name: 'DocTmxRequestViewer',
  components: {
    HeroTitle, DocTmxFizetos, DocTmxGarancia,
  },
  async beforeMount() {
    // console.log('ExternalRequestDetailsPage beforeMount: ', this.$route.params);
    if (this.loginMethod === 1) {
      if (this.$route.params.id && this.$route.params.token) {
        await this.getRequest(this.$route.params.id, this.$route.params.token);
      }
    } else {
      //
    }
  },
  props: {
    //
  },
  data() {
    return {
      serviceRequest: {},
      loadingDialog: {
        visible: false,
        title: '',
      },
      responseDialog: {
        visible: false,
        title: '',
        message: '',
        icon: '',
      },
      loadingRequestData: true,
      loginIsSuccess: false,
      crmRequestId: null,
      loginMethod: 1,
    };
  },
  methods: {
    async getRequest(id, token) {
      const response = await this.$store.dispatch('getRequestByQr', { id: id, token: token });
      // console.log('getRequest response: ', response);
      if (response.status === 200) {
        this.serviceRequest = response.data;
        setTimeout(this.setLoginSuccess, 2200);
      } else {
        this.loginIsSuccess = false;
      }
      setTimeout(this.setLoadingEnd, 2200);
    },
    setLoginSuccess() {
      this.loginIsSuccess = true;
    },
    setLoadingEnd() {
      this.loadingRequestData = false;
    },
    async reloadPage() {
      this.loadingRequestData = true;
      await this.getRequest(this.$route.params.id, this.$route.params.imei, this.$route.params.token);
    },
  },
};
</script>

<style scoped>

</style>
