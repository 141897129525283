<template>
  <div v-frag>
    <v-container>
      <v-row>
        <!-- KÖVETÉS START -->
        <template v-if="showStatus === 1">
          <v-col cols="12"
                 md="6"
                 lg="4">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-timeline-text-outline</v-icon>
                <span class="subtitle-1">{{ $t('tracking') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-list two-line class="py-0">
                  <!-- KÖVETÉS START -->
                  <template v-for="(item, index) in serviceRequest.history">
                    <v-list-item :key="index" v-show="item.code !== 40 && item.code !== 99 && item.code !== 80 && item.code !== 70">
                      <v-list-item-avatar>
                        <v-icon :color="item.date ? 'success' : 'grey lighten-1'">
                          {{ item.date ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title :class="item.date ? 'font-weight-bold' : 'font-weight-bold grey--text lighten-1'">
                          {{ item.status }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.dateToDisplay }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="item.date && (item.documents && item.documents.length > 0)">
                        <v-tooltip top v-for="(item, index) in item.documents" :key="index">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" v-on="on" @click="downloadFile(item.status)">
                              mdi-file-download-outline
                            </v-icon>
                          </template>
                          <span>
                            {{ item.name }} letöltése
                          </span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                  <!-- KÖVETÉS END -->
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <!-- KÖVETÉS END -->
        <!-- RÉSZLETEK START -->
        <v-col cols="12"
               :md="showStatus === 0 ? 7 : 6"
               lg="8">
          <!-- IGÉNY ADATOK START -->
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-tablet-cellphone</v-icon>
              <span class="subtitle-1">{{ $t('request.requestData') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequest.serviceRequest.id"
                                    :label="$t('serviceRequestId')"
                                    filled
                                    hide-details
                                    disabled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="$store.state.authModule.user && $store.state.authModule.user.profile.customerType === 'EMPLOYEE'">
                      <v-text-field v-model="serviceRequest.serviceRequest.gsmWorksheetId"
                                    :label="$t('gsmWorksheetId')"
                                    filled
                                    hide-details
                                    disabled>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select v-model="serviceRequest.serviceRequest.warrantyType"
                            :items="$store.state.selectfields.warrantyType"
                            item-text="name"
                            item-value="id"
                            :label="$t('inputLabels.warrantyType')"
                            filled
                            hide-details
                            disabled
                            return-object
                            required>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      v-model="serviceRequest.serviceRequest.purchaseDate"
                      :label="$t('inputLabels.dateOfPurchase')"
                      filled
                      hide-details
                      disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.brand.name"
                                :label="$t('inputLabels.manufacturer')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.productType.tradeName"
                                :label="$t('inputLabels.productType')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      v-model="serviceRequest.serviceRequest.networkLock.name"
                      :label="$t('inputLabels.simLock')"
                      filled
                      hide-details
                      disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn1"
                                :label="$t('inputLabels.uniqueIdentifierIn1new')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn2"
                                :label="$t('inputLabels.uniqueIdentifierIn2new')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn3"
                                :label="$t('inputLabels.uniqueIdentifierIn3new')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="serviceRequest.serviceRequest.errorDescription"
                              :label="$t('inputLabels.troubleDescription')"
                              filled
                              hide-details
                              auto-grow
                              disabled>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- IGÉNY ADATOK END -->
        </v-col>
        <!-- RÉSZLETEK END -->
        <!-- ÜGYFÉL ADATOK START -->
        <template v-if="showUser === 1">
          <v-col cols="12"
                 :md="showStatus === 0 ? 5 : 12"
                 :lg="showStatus === 0 ? 4 : 12">
            <v-card :class="showStatus === 0 ? 'fill-height' : ''">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
                <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12"
                         sm="6"
                         :md="showStatus === 0 ? 12 : 4">
                    <v-text-field v-model="customerNameString"
                                  :label="$t('contactName')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12"
                         sm="6"
                         :md="showStatus === 0 ? 12 : 4">
                    <v-text-field v-model="serviceRequest.customer.phone"
                                  :label="$t('phoneNumber')"
                                  prefix="+36/"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12"
                         sm="6"
                         :md="showStatus === 0 ? 12 : 4">
                    <v-text-field v-model="serviceRequest.customer.email"
                                  :label="$t('email')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-2">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12"
                         sm="6"
                         :md="showStatus === 0 ? 12 : 6">
                    <v-textarea v-model="shippingAddressString"
                                  :label="$t('shippingAddress')"
                                  filled
                                rows="1"
                                auto-grow
                                  hide-details
                                  disabled>
                    </v-textarea>
                  </v-col>
                  <v-col cols="12"
                         sm="6"
                         :md="showStatus === 0 ? 12 : 6">
                    <v-textarea v-model="billingAddressString"
                                :label="$t('invoicingAddress')"
                                filled
                                rows="1"
                                auto-grow
                                hide-details
                                disabled>
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <!-- ÜGYFÉL ADATOK END -->
        <!-- DOKUMENTUMOK START -->
        <v-col cols="12"
               :md="showUser === 0 && showStatus === 0 ? 5 : 12"
               :lg="showUser === 0 && showStatus === 0 ? 4 : 12">
          <v-card :class="showUser === 0 && showStatus === 0 ? 'fill-height' : ''">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-file-document-outline</v-icon>
              <span class="subtitle-1">{{ $t('documents') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <!-- DOKUMENTUMOK LISTÁZÁSA ÚJ -->
                <template v-for="(document, index) in serviceRequest.documents">
                  <v-col col="12"
                         :sm="showUser === 0 && showStatus === 0 ? 12 : 4"
                         :key="index"
                         v-show="document.hash && document.table">
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar size="32">
                              <v-avatar color="primary">
                                <v-icon color="white">
                                  mdi-pdf-box
                                </v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="">
                                {{ document.description }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ document.createdAt }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="showUser === 0 && showStatus === 0">
                              <v-icon color="primary" @click="downloadFile(document)">
                                mdi-download
                              </v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                      <v-card-actions class="px-3" v-if="showUser === 1 || showStatus === 1">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="downloadFile(document)">
                          {{ $t('button.download') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- DOKUMENTUMOK END -->
      </v-row>
    </v-container>
    <!--document-uploader :crm-request-id="serviceRequest.serviceRequest.id" :is-visible="isUploadCardVisible"></document-uploader-->
    <v-dialog v-model="downloadFileErrorDialog.visible" max-width="440px">
      <v-card>
        <v-card-title>
          <v-icon color="error" class="mr-4">mdi-alert</v-icon>
          <span class="subtitle-1">{{ $t('downloadFileErrorDialog.title') }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <div class="subtitle-1">
            {{ $t('downloadFileErrorDialog.noFile') }}
          </div>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="downloadFileErrorDialog.visible = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import DocumentUploader from "./DocumentUploader.vue";
import {
  ONLINE_PAYMENT_ENABLED,
  PP_ENABLED,
  CREDIT_CARD_ENABLED,
  LOCATION } from 'src/utils/systemParameters';
import { SHOW_DOC_TMX_USER, SHOW_DOC_TMX_STATUS } from 'src/utils/systemParameters';

export default {
  name: 'DocTmxFizetos',
  components: {
    // DocumentUploader,
  },
  props: {
    serviceRequest: {
      type: Object,
    }
  },
  mounted() {
    this.productString = `${this.serviceRequest.serviceRequest.brand.name} ${this.serviceRequest.serviceRequest.productType.tradeName}`;
    this.customerNameString = `${this.serviceRequest.customer.lastName} ${this.serviceRequest.customer.firstName}`;
    this.shippingAddressString = `${this.serviceRequest.shippingAddressData.zipCode} ${this.serviceRequest.shippingAddressData.city}, ${this.serviceRequest.shippingAddressData.publicPlaceName} ${this.serviceRequest.shippingAddressData.publicPlaceType} ${this.serviceRequest.shippingAddressData.houseNumber}`;
    this.billingAddressString = `${this.serviceRequest.billingAddressData.billingName} - ${this.serviceRequest.billingAddressData.zipCode} ${this.serviceRequest.billingAddressData.city}, ${this.serviceRequest.billingAddressData.publicPlaceName} ${this.serviceRequest.billingAddressData.publicPlaceType} ${this.serviceRequest.billingAddressData.houseNumber}`;
    this.hasActiveQuote = this.checkHasActiveQuote();
    this.checkIsUploadCardVisible();
    if (this.serviceRequest.currentStatus === 30) {
      this.quoteDoc = this.findQuote();
    }
  },
  data() {
    return {
      productString: '',
      customerNameString: '',
      shippingAddressString: '',
      billingAddressString: '',
      hasActiveQuote: false,
      isUploadCardVisible: false,
      quoteDoc: {},
      downloadFileErrorDialog: {
        visible: false,
        message: 'downloadFileErrorDialog.noFile',
      },
      paymentProvider: null,
      onlinePaymentEnabled: ONLINE_PAYMENT_ENABLED,
      payPalEnabled: PP_ENABLED,
      creditCardEnabled: CREDIT_CARD_ENABLED,
      showStatus: SHOW_DOC_TMX_STATUS,
      showUser: SHOW_DOC_TMX_USER,
    };
  },
  methods: {
    async downloadFile(document) {
      // console.log('document: ', document);
      if (document !== {}) {
        let fileHandler = {
          crmRequestId: this.serviceRequest.serviceRequest.id,
          table: document.table,
          hash: document.hash,
        }
        const respone = await this.$store.dispatch('downloadDocument', { form: fileHandler });
      } else {
        this.downloadFileErrorDialog.visible = true;
      }
    },
    findQuote() {
      let file = this.serviceRequest.documents.find((item) => {
        return item.description === 'Árajánlat'
      });
      return file || {};
    },
    quoteAccept() {
      this.$emit('quote-accept');
    },
    quoteReject() {
      this.$emit('quote-reject');
    },
    checkHasActiveQuote() {
      if (this.serviceRequest.documents) {
        let ajanlat = this.serviceRequest.history.find(item => item.code === 30);
        // console.log('ajanlat: ', ajanlat);
        let elfogadott = this.serviceRequest.history.find(item => item.code === 32);
        if(!elfogadott) {
          elfogadott = {date: null};
        }
        let elutasitott = this.serviceRequest.history.find(item => item.code === 34);
        if(!elutasitott) {
          elutasitott = {date: null};
        }
        let nincsvalasz = this.serviceRequest.history.find(item => item.code === 36);
        if(!nincsvalasz) {
          nincsvalasz = {date: null};
        }
        if(nincsvalasz.date || elutasitott.date || elfogadott.date) {
          return false;
        } else if(ajanlat.date) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      };
    },
    checkIsUploadCardVisible() {
      const item = this.serviceRequest.history.findIndex(x => x.code === 5 && x.date);
      // console.log('checkIsUploadCardVisible item: ', item);
      if (item > 0) {
        this.isUploadCardVisible = false;
      } else {
        this.isUploadCardVisible = true;
      }
    },
    async createOrder() {
      const order = {
        crmRequestId: this.serviceRequest.serviceRequest.id,
        paymentProvider: 'paypal',
        successUrl: `${LOCATION}/sikeres-fizetes`,
        cancelUrl: `${LOCATION}/visszavont-fizetes`,
        failureUrl: `${LOCATION}/sikertelen-fizetes`,
        totalPrice: this.serviceRequest.serviceRequest.grossPrice,
      };
      const response = await this.$store.dispatch('createPPOrder', order);
      // console.log('createOrder response: ', response);
      if (response.status === 201) {
        window.open(`${response.data.redirectUrl}`, '_self');
      }
    },
  },
};
</script>

<style scoped>

</style>
